import { useQuery } from 'react-query';
import { apiConfig } from '../../../apiConfig';
import { message } from 'antd';
import { getToken } from '../../../utils/getToken';
import { axiosInstance } from '../../../axiosInstance';

export const useGetMembersQuery = () => {
  const token = getToken();

  return useQuery({
    queryKey: ['useGetMembersQuery'],
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.MEMBERS}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    onSuccess: (data) => { },
    onError: (error: any) => {
      message.error(error?.response?.data?.message);
    },
    enabled: !!token,
  });
};


export const useGetMemberQuery = ({ id }: { id: any }) => {
  const token = getToken();

  return useQuery({
    queryKey: ['useGetMemberQuery'],
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.MEMBERS}/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    onSuccess: (data) => { },
    onError: (error: any) => {
      message.error(error?.response?.data?.message);
    },
    enabled: !!token,
  });
};

import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSigninUser } from '../../hooks/api/Auth/useLoginUser';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AuthLayout from '../../common/AuthLayout';

const Login = () => {
  const [payload, setPayload] = useState({ email: '', password: '' });
  const [visibility, setVisibility] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const { isLoading, mutateAsync } = useSigninUser();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutateAsync(payload);
  };

  return (
    <AuthLayout
      rightLink={
        <span>
          Don't have an account?{' '}
          <Link to="/register" className="text-green font-bold">
            Create Account
          </Link>
        </span>
      }
    >
      <form
        className="mx-auto w-[600px] bg-white px-[100px] py-[40px]"
        onSubmit={handleSubmit}
      >
        <p className="text-center font-bold mb-[46px] text-[24px]">
          Welcome Back
        </p>
        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 absolute text-[#141414] left-[25px] top-[25px]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
            />
          </svg>

          <input
            type="text"
            name="email"
            placeholder="Enter Email Address"
            autoCorrect="false"
            disabled={isLoading}
            onChange={handleChange}
            className="w-full rounded-full py-[24px] px-[55px] border-[1px] border-[#00000033] mb-[16px]"
          />
        </div>
        <div className="relative">
          <input
            type={!visibility ? 'password' : 'text'}
            name="password"
            disabled={isLoading}
            placeholder="Enter Your Password"
            onChange={handleChange}
            className="w-full rounded-full py-[24px] px-[55px] border-[1px] border-[#00000033] mb-[16px]"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 absolute text-[#141414] left-[25px] top-[25px]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z"
            />
          </svg>

          <span
            onClick={() => setVisibility(!visibility)}
            className="absolute top-[25px] right-[25px] cursor-pointer"
          >
            {!visibility ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
            )}
          </span>
        </div>
        <div>
          <Link to="/reset" className="text-green font-bold">
            Forgot Password?
          </Link>
        </div>
        <button
          disabled={!payload.email || !payload.password || isLoading}
          className={`bg-green rounded-full p-[24px] font-bold text-white w-full mb-[40px] mt-[16px] ${
            isLoading ? 'cursor-not-allowed opacity-50' : ''
          }`}
          type="submit"
        >
          {isLoading ? (
            <Spin
              className="text-white"
              size={'large'}
              indicator={<LoadingOutlined spin />}
            />
          ) : (
            'Proceed'
          )}
        </button>{' '}
        <div className="text-center">
          By proceeding you agree to the 
          <Link to="/privacy" target="_blank" className="text-green underline">
            Privacy Policy
          </Link>{' '}
          and 
          <Link to="/terms" target="_blank" className="text-green underline">
            Terms of Use
          </Link>
        </div>
      </form>{' '}
    </AuthLayout>
  );
};

export default Login;

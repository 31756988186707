import React from "react";
import { QueryClientProvider } from "react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// PAGES
import Home from "./pages/HomePage";
import Login from "./pages/Auth/Login";
import Overview from "./pages/Dashboard/Overview";
import ResetPassword from "./pages/Auth/ResetPassword";
import Register from "./pages/Auth/Register";
import Members from "./pages/Dashboard/Members";
import { queryClient } from "./services/queryClient";
import MemberDetails from "./pages/Dashboard/Members/MemberDetails";
import AppLayout from "./pages/Dashboard/AppLayout";
import MemberSavings from "./pages/Dashboard/Members/MemberSavings";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },  {
    path: "/terms",
    element: <Terms />,
  }, {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/reset",
    element: <ResetPassword />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/overview",
    element: <Overview />,
  },
  {
    path: "/member-management",
    element: <Members />,
  },
  {
    path: "/member-management/:id",
    element: <MemberDetails />,
  },
  {
    path: "/member-management/savings/:id",
    element: <MemberSavings />,
  },
  {
    path: "/accounts",
    element: (
      <AppLayout title="Accounts">
        <p>ACCOUNTS</p>
      </AppLayout>
    ),
  },
  {
    path: "/loan-management",
    element: (
      <AppLayout title="Loan Management">
        <p>LOAN MANAGEMENT</p>
      </AppLayout>
    ),
  },
  {
    path: "/deposit-savings",
    element: (
      <AppLayout title="Deposit Savings">
        <p>DEPOSITS</p>
      </AppLayout>
    ),
  },
  {
    path: "/shares-dividend",
    element: (
      <AppLayout title="Shares Dividends">
        <p>DIVIDENDS</p>
      </AppLayout>
    ),
  },
  {
    path: "/transactions",
    element: (
      <AppLayout title="Transactions">
        <p>TRANSACTIONS</p>
      </AppLayout>
    ),
  },
  {
    path: "/reports-analytics",
    element: (
      <AppLayout title="Reports">
        <p>REPORTS</p>
      </AppLayout>
    ),
  },
  {
    path: "/settings",
    element: (
      <AppLayout title="Settings">
        <p>SETTINGS</p>
      </AppLayout>
    ),
  },
  { path: "*", element: <p>ERROR</p> },
]);

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};

export default App;

import React from "react";
import PageLayout from "../../common/PageLayout";

type SectionProps = {
  content: string;
};

type ListProps = {
  items: string[];
};

const Header = ({ content }: SectionProps) => {
  return <h3 className="text-green font-bold mt-6 mb-2">{content}</h3>;
};

const Body = ({ content }: SectionProps) => {
  return <p className="mb-4">{content}</p>;
};

const List = ({ items }: ListProps) => {
  return (
    <ul className="list-disc list-inside mb-4">
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};

const Privacy = () => {
  return (
    <PageLayout>
      <div className="container mx-auto px-2 mb-[50px]">
      <div className="heading text-3xl text-green mt-6 font-bold">
          Privacy Policy
        </div>

        <Header content="Introduction" />
        <Body content="At CoTrackr, we are committed to protecting the privacy of our users and ensuring the security of their data. This Privacy Policy outlines how we collect, use, and safeguard the information provided by users of our platform. By using CoTrackr, you consent to the collection and use of your data as described in this policy." />

        <Header content="1. Information We Collect" />
        <Body content="We may collect the following types of information:" />
        <List
          items={[
            "Personal Information: Names, email addresses, phone numbers, and other contact information of members and administrators.",
            "Financial Information: Data related to savings, loans, shares, dividends, and other financial transactions conducted through CoTrackr.",
            "Usage Data: Information about how users interact with our platform, including IP addresses, browser types, device information, and usage patterns.",
          ]}
        />

        <Header content="2. How We Use Your Information" />
        <Body content="We use the information we collect to:" />
        <List
          items={[
            "Provide and improve our services.",
            "Facilitate member and account management.",
            "Process financial transactions such as savings, loans, and dividends.",
            "Generate reports and analytics for administrators.",
            "Communicate with users regarding updates, security, or changes to the platform.",
          ]}
        />

        <Header content="3. Legal Basis for Processing" />
        <Body content="We process your personal data based on the following legal grounds:" />
        <List
          items={[
            "Consent: Where you have given explicit consent to process your personal data for a specific purpose.",
            "Contractual Necessity: To fulfill the terms of any contract we have with you.",
            "Legitimate Interest: Where processing your personal data is necessary for our legitimate interests, provided that your fundamental rights are not infringed.",
          ]}
        />

        <Header content="4. Data Security" />
        <Body content="We take the security of your data seriously. We use industry-standard encryption and security protocols to protect your information from unauthorized access, disclosure, or alteration. However, no method of data transmission over the internet or electronic storage is completely secure, and we cannot guarantee its absolute security." />

        <Header content="5. Sharing Your Information" />
        <Body content="We do not sell, trade, or otherwise transfer your personal information to outside parties except when necessary to provide our services, comply with the law, or protect our rights. In some cases, we may share anonymized data with third parties for analytics and improvement purposes." />

        <Header content="6. Cross-Border Data Transfers" />
        <Body content="We may transfer your data to countries outside Nigeria for processing or storage. When we do so, we ensure that adequate safeguards are in place to protect your data in compliance with the Nigeria Data Protection Act 2023, applicable extant Laws, and other relevant international standards." />

        <Header content="7. Data Retention" />
        <Body content="We retain personal data only for as long as is necessary for the purposes outlined in this policy, unless a longer retention period is required or permitted by law." />

        <Header content="8. Your Rights" />
        <Body content="You have the right to:" />
        <List
          items={[
            "Access, correct, or delete your personal information.",
            "Object to or restrict the processing of your data.",
            "Request data portability to another service provider.",
            "Withdraw your consent at any time, without affecting the legality of data processing conducted before withdrawal.",
          ]}
        />

        <Header content="9. Breach Notification" />
        <Body content="In the event of a data breach that affects your personal information, we will notify you and the relevant regulatory authorities as required by law. This notification will occur as soon as reasonably possible after the breach has been identified." />

        <Header content="10. Changes to This Privacy Policy" />
        <Body content="We reserve the right to update this policy as necessary. Changes will be posted on this page, and we will notify users through the platform when significant changes are made." />

        <Header content="11. Children's Privacy" />
        <Body content="CoTrackr’s services are not intended for children under the age of 18. We do not knowingly collect personal data from children. If we discover that a child under 18 has provided us with personal information, we will take steps to delete such information." />

        <Header content="12. Contact us" />
        <Body content="If you have any questions or concerns about this Privacy Policy, please contact us at admin@cotrackr.com." />
      </div>
    </PageLayout>
  );
};

export default Privacy;

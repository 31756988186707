import { useQuery } from 'react-query';
import { message } from 'antd';
import { getToken } from '../../utils/getToken';
import { axiosInstance } from '../../axiosInstance';
import { apiConfig } from '../../apiConfig';

export const useGetEntriesQuery = ({search, tag, isDeleted, startDate, endDate}: {search: string, tag: string, isDeleted: boolean, startDate: string, endDate: string}) => {
  const token = getToken();

  return useQuery({
    queryKey: ['useGetEntriesQuery', search, tag, isDeleted, startDate, endDate ],
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.ENTRIES}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    onSuccess: (data) => {},
    onError: (error: any) => {
      message.error(error?.response?.data?.message);
    },
    enabled: !!token,
  });
};

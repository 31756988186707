import { useMutation } from 'react-query';
import { apiConfig } from '../../../apiConfig';
import { message } from 'antd';
import { queryClient } from '../../../services/queryClient';
import { getToken } from '../../../utils/getToken';
import { axiosInstance } from '../../../axiosInstance';

interface Props {
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  address: string;
  city: string;
  state: string;
}

export const useAddMembers = () => {
  const token = getToken();

  return useMutation(
    (payload: Props) =>
      axiosInstance
        .post(`${apiConfig.MEMBERS}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries('useGetMembersQuery', {
          refetchActive: true,
        });

        message.success(data?.message);
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message);
      },
    }
  );
};

import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Button, message, Spin, Upload } from 'antd';
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { useUpdateUserPreference } from '../../../hooks/api/Profile/useUpdateUserPreference';
import Dragger from 'antd/es/upload/Dragger';
import { useUploadFile } from '../../../hooks/api/Misc/useUploadFile';
import { UploadOutlined } from '@ant-design/icons';

const CompleteRegistration = ({ setOpen }: { setOpen: any }) => {
  const Image = require('../../../assets/images/CompleteRegistration.png');
  const Logo = require('../../../assets/images/Logo.png');

  const [payload, setPayload] = useState({
    profileImage: '',
    societyName: '',
    monthlySavings: '',
    memberSize: '',
  });

  const { mutateAsync, isLoading } = useUpdateUserPreference(payload);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutateAsync(data, {
      onSuccess: () => {
        setOpen(false);
      },
    });
  };

  const {
    mutateAsync: uploadFile,
    isLoading: isUploading,
    data,
  } = useUploadFile();

  const handleFileChange = async (info: any) => {
    const { originFileObj } = info.file;

    if (originFileObj) {
      try {
        await uploadFile(originFileObj, {
          onSuccess: async (data) => {
            // message.success('successful upload');
            await setPayload({ ...payload, profileImage: data?.data });
          },
        });
      } catch (error) {
        // message.error(error?.data);
        console.error('Upload failed:', error);
      }
    }
  };

  const formatNumberWithCommas = (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Remove commas for processing the actual numeric value
    const numericValue = value.replace(/,/g, '');

    setPayload({
      ...payload,
      [name]: numericValue,
    });
  };

  return (
    <div className="flex overflow-hidden h-[90vh]">
      <div className="w-6/12 overflow-y-scroll">
        <div className="max-w-[400px] mx-auto">
          <div className="mt-8">
            <h3 className="text-[24px] mb-[5px]">Complete registration</h3>
            <p className="mb-[100px]">Almost done. Just a few steps to go !</p>
            <form className="" onSubmit={handleSubmit}>
              {!data ? (
                <Upload onChange={handleFileChange} listType="picture">
                  <Button type="dashed" icon={<UploadOutlined />}>
                    Upload Profile Picture
                  </Button>
                </Upload>
              ) : (
                <img
                  src={data?.data}
                  className="h-[100px] w-[100px] rounded-full"
                  alt=""
                />
              )}
              <input
                type="text"
                name="societyName"
                placeholder="Society Name"
                autoCorrect="false"
                value={payload?.societyName}
                disabled={isLoading}
                onChange={handleChange}
                className="w-full rounded-full p-[24px] mt-4 border-[1px] border-[#00000033] mb-[16px]"
              />
              <h3 className="text-[24px] mb-[10px]">Select Preferences</h3>
              <select
                name="memberSize"
                disabled={isLoading}
                onChange={handleChange}
                value={payload?.memberSize}
                className="w-full rounded-full p-[24px] border-[1px] border-[#00000033] mb-[16px]"
              >
                <option value="">Select option</option>
                <option value="1-500">
                  Small Scale Co-operative (1-500 Members)
                </option>
                <option value="501-1000">
                  Medium Scale Co-operative (501-1000 Members)
                </option>
                <option value="1000 and above">
                  Large Scale Co-operative (above 1000 Members)
                </option>
              </select>
              <input
                type="text"
                name="monthlySavings"
                placeholder="Monthly Savings"
                autoCorrect="false"
                value={formatNumberWithCommas(payload.monthlySavings)}
                disabled={isLoading}
                onChange={handleInputChange}
                className="w-full rounded-full p-[24px] border-[1px] border-[#00000033] mb-[16px]"
              />
              <button
                disabled={
                  !payload.memberSize ||
                  !payload.monthlySavings ||
                  !payload.societyName ||
                  !payload?.profileImage
                }
                className={`bg-green rounded-full p-[24px] font-bold text-white w-full mb-[40px] mt-[16px]
             ${
               !payload.memberSize ||
               !payload.monthlySavings ||
               !payload.societyName ||
               !payload?.profileImage
                 ? 'cursor-not-allowed opacity-70'
                 : ''
             }`}
                type="submit"
              >
                {isLoading ? (
                  <Spin
                    className="text-white"
                    size={'large'}
                    indicator={<LoadingOutlined spin />}
                  />
                ) : (
                  'Proceed'
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div
        className="w-6/12 h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${Image})` }}
      ></div>
    </div>
  );
};

export default CompleteRegistration;

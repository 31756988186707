import { Spin } from 'antd';
import React from 'react';

type Props = {};

const MemberSkeleton = (props: Props) => {
  return (
    <div>
      <div className="my-[100px] ">
        {/* <div className="loader"></div> */}
        <Spin className="flex mx-auto" />
      </div>
    </div>
  );
};

export default MemberSkeleton;

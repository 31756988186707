import React, { ReactNode, useEffect, useState } from "react";
import {  Drawer, Layout, theme } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGetMeQuery } from "../../../hooks/api/User/useGetMe";
import withAuth from "../../../hoc/useAuth";
import CompleteRegistration from "../CompleteRegistration";
import WelcomeLoader from "../../../common/WelcomeLoader";

const { Header } = Layout;
type Props = { children: ReactNode; title: string };

const AppLayout = ({ children, title }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const Logo = require("../../../assets/images/logo-white.png");
  const { data } = useGetMeQuery();

  useEffect(() => {
    if (data && data?.data?.society?.societyName === "") {
      setOpen(true);
    } else return;
  }, [data]);

  const formatDate = (date: Date) => {
    return date.toLocaleDateString("en-GB", {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  const date = new Date();
  const formattedDate = formatDate(date);

  return (
    <Layout className="h-screen">
      <WelcomeLoader />
      <div className="flex flex-row">
        <div className="w-[250px] h-dvh text-white bg-[#21818D] relative">
          <div>
            <img
              src={Logo}
              alt="logo"
              className="mt-[10px] mb-[30px] mx-[10px] h-[50px] w-[59px]"
            />
            <div className="mt-4">
              <Link
                to="/overview"
                className={`flex gap-2 items-center mb-[5px] p-2 ${
                  pathname?.includes("/overview")
                    ? "bg-white text-green"
                    : "text-white"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
                  />
                </svg>
                <span>Overview</span>
              </Link>
              <Link
                to="/member-management"
                className={`flex gap-2 items-center mb-[5px] p-2 ${
                  pathname?.includes("/member-management")
                    ? "bg-white text-green"
                    : "text-white"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                  />
                </svg>
                <span>Member Management</span>
              </Link>
              <Link
                to="/accounts"
                className={`flex gap-2 items-center mb-[5px] p-2 ${
                  pathname?.includes("/accounts")
                    ? "bg-white text-green"
                    : "text-white"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"
                  />
                </svg>
                <span>Accounts</span>
              </Link>
              <Link
                to="/loan-management"
                className={`flex gap-2 items-center mb-[5px] p-2 ${
                  pathname?.includes("/loan-management")
                    ? "bg-white text-green"
                    : "text-white"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                  />
                </svg>
                <span>Loan Management</span>
              </Link>
              <Link
                to="/deposit-savings"
                className={`flex gap-2 items-center mb-[5px] p-2 ${
                  pathname?.includes("/deposit-savings")
                    ? "bg-white text-green"
                    : "text-white"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3"
                  />
                </svg>
                <span>Deposit Savings</span>
              </Link>
              <Link
                to="/shares-dividend"
                className={`flex gap-2 items-center mb-[5px] p-2 ${
                  pathname?.includes("/shares-dividend")
                    ? "bg-white text-green"
                    : "text-white"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                  />
                </svg>
                <span>Shares Dividend</span>
              </Link>
              <Link
                to="/transactions"
                className={`flex gap-2 items-center mb-[5px] p-2 ${
                  pathname?.includes("/transactions")
                    ? "bg-white text-green"
                    : "text-white"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.242 5.992h12m-12 6.003H20.24m-12 5.999h12M4.117 7.495v-3.75H2.99m1.125 3.75H2.99m1.125 0H5.24m-1.92 2.577a1.125 1.125 0 1 1 1.591 1.59l-1.83 1.83h2.16M2.99 15.745h1.125a1.125 1.125 0 0 1 0 2.25H3.74m0-.002h.375a1.125 1.125 0 0 1 0 2.25H2.99"
                  />
                </svg>
                <span>Transactions</span>
              </Link>
              <Link
                to="/reports-analytics"
                className={`flex gap-2 items-center mb-[5px] p-2 ${
                  pathname?.includes("/reports-analytics")
                    ? "bg-white text-green"
                    : "text-white"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z"
                  />
                </svg>
                <span>Report Analytics</span>
              </Link>
              <Link
                to="/settings"
                className={`flex gap-2 items-center mb-[5px] p-2 ${
                  pathname?.includes("/settings")
                    ? "bg-white text-green"
                    : "text-white"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span>Settings & Security</span>
              </Link>
            </div>
          </div>

          {data && data?.data?.society?.societyName !== "" && (
            <div className="absolute bottom-4 w-full px-2">
              <div className="flex gap-2 items-center">
                <img
                  src={data?.data?.society?.profileImage}
                  className="h-[50px] w-[50px] rounded-[6px]"
                  alt=""
                />
                <span>
                  <p className="text-white font-bold">
                    {data?.data?.society?.societyName}{" "}
                  </p>
                  <p className="text-white">
                    {data?.data?.society?.memberSize === "1-500" &&
                      "Basic Bundle"}
                    {data?.data?.society?.memberSize === "501-1000" &&
                      "Premium Bundle"}
                    {data?.data?.society?.memberSize === "1000 and above" &&
                      "Large Scale"}
                  </p>
                </span>
              </div>
              <button
                className="bg-red-500 p-2 rounded-md text-white font-bold flex gap-2 items-center mt-2 ml-[60px]"
                onClick={() => {
                  localStorage.removeItem("COTRACKR_USER_TOKEN");
                  localStorage.removeItem("hasSeenWelcomeLoader");
                  navigate("/");
                }}
              >
                <span className="text-[12px]">Sign out</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={3}
                  stroke="currentColor"
                  className="size-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
        <div className="w-full h-screen flex flex-col">
          <Header
            style={{ padding: 0, background: colorBgContainer }}
            className="shadow-sm z-20"
          >
            <div className="flex justify-between items-center px-[20px]">
              <p className="text-[20px] font-bold">{title}</p>
              <span className="bg-gray-200 h-[30px] p-3 rounded-md grounded-full flex items-center gap-2 text-[14px] font-medium">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008H16.5V15Z"
                  />
                </svg>
                {formattedDate}
              </span>
            </div>
          </Header>
          <div className="px-[20px] flex-1 overflow-y-auto py-4">
            {children}
          </div>
        </div>
      </div>

      <Drawer
        placement={"bottom"}
        closable={false}
        height={"90vh"}
        open={open}
        maskClosable={false}
        key={3}
        className="p-0"
      >
        <CompleteRegistration setOpen={setOpen} />
      </Drawer>
    </Layout>
  );
};

export default withAuth(AppLayout);

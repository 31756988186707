import React, { ReactNode } from 'react';

type Props = {
  title: string;
  right?: string | ReactNode;
  subtitle?: string;
  children?: string | ReactNode;
  headerSize?: string;
  className?: string;
};

const Card = ({
  title,
  right,
  subtitle,
  children,
  headerSize,
  className,
}: Props) => {
  return (
    <div
      className={`bg-white rounded-[10px] py-[24px] px-[16px] w-full ${className}`}
    >
      <div className="flex items-center justify-between">
        <div>
          <h3
            className={`text-dark font-bold ${
              headerSize ? headerSize : 'text-[20px]'
            }`}
          >
            {title}
          </h3>
          <p>{subtitle}</p>
        </div>
        <div>{right}</div>
      </div>
      {children}
    </div>
  );
};

export default Card;
